<template>
  <div
    :class="{
      'stripe-element--has-icon': hasIcon,
      'stripe-element--focus': hasFocus,
      'stripe-element--complete': isComplete,
      'stripe-element--error': hasError,
      'stripe-element--disabled': disabled
    }"
    class="stripe-element tw-relative tw-bg-white tw-flex tw-flex-col tw-justify-center tw-px-2"
    @click="focus"
  >
    <label
      :for="target"
      v-text="hasError ? errorMessage : label"
      class="stripe-element__label"
      data-test="label"
    />
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - StripeElement
   * @param {any} [stripeElements=null]
   * @param {string} label
   * @param {string} type - Stripe Element type
   * @param {string} target - ID for the mount element
   * @param {boolean} [disabled=false]
   * @param {object} options - Additional options to pass to Stripe element
   * @param {boolean} [hasIcon=false]
   */
  export default {
    props: {
      stripeElements: {
        type: Object,
        default: null
      },
      label: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      target: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => ({})
      },
      hasIcon: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        hasFocus: false,
        hasError: false,
        isComplete: false,
        /** @type {any} */
        stripeElement: null,
        errorMessage: null,
        defaultStyle: {
          base: {
            fontFamily: 'Nunito Sans, Open Sans, Segoe UI, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            ':disabled': {
              color: '#4a5568'
            }
          },
          invalid: {
            iconColor: '#D7283F',
            color: '#D7283F'
          }
        }
      }
    },
    mounted () {
      this.createElement()
    },
    watch: {
      stripeElements () {
        this.createElement()
      },
      disabled (value) {
        this.updateOptions({
          disabled: value
        })
      }
    },
    methods: {
      clear () {
        if (this.stripeElement) {
          this.stripeElement.clear()
        }
      },
      focus () {
        if (this.stripeElement) {
          this.stripeElement.focus()
        }
      },
      /**
       * @function updateOptions
       * @param {object} options
       */
      updateOptions (options) {
        if (this.stripeElement) {
          this.stripeElement.update(options)
        }
      },
      createElement () {
        if (!this.stripeElements) {
          return
        }

        this.stripeElement = this.stripeElements.create(this.type, {
          style: this.defaultStyle,
          disabled: this.disabled,
          ...this.options
        })
        this.stripeElement.mount(document.querySelector(`#${this.target}`))
        this.stripeElement.on('change', (event) => {
          if (event.error && event.error.type === 'validation_error') {
            /**
             * Override default Stripe error messages with custom messages
             * that are shorter.
             */
            switch (event.error.code) {
            case 'invalid_expiry_year':
            case 'invalid_expiry_year_past':
            case 'incomplete_cvc':
            case 'incomplete_expiry':
              // @ts-ignore
              this.errorMessage = this.$t(`app.errors.credit_card.expiry.${event.error.code}`)
              break
            default:
              this.errorMessage = event.error.message
              break
            }
          }

          this.hasError = !!event.error
          this.isComplete = event.complete
        })
        this.stripeElement.on('focus', () => {
          this.hasFocus = true
        })
        this.stripeElement.on('blur', () => {
          this.hasFocus = false
        })
      }
    },
    beforeDestroy () {
      if (this.stripeElement) {
        this.stripeElement.destroy()
      }
    }
  }
</script>

<style lang="scss">
.stripe-element {
  transition: border 200ms ease-in-out;
  height: 45px;
  border: 1px solid #D2D2D2;
}
.stripe-element__label {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  margin-bottom: 0px;
  pointer-events: none;
  position: absolute;
  z-index: 10;
  transition: color 200ms ease-in-out, font-size 200ms, transform 200ms ease-in-out;
  color: rgba(0, 0, 0, 0.54);
  width: calc(100% - 1rem);
}
.stripe-element .StripeElement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: transform 200ms ease-in-out;
}
.stripe-element--focus:not(.stripe-element--error) {
  --tw-border-opacity: 1;
  border-color: rgba(39, 84, 145, var(--tw-border-opacity));
}
.stripe-element--focus:not(.stripe-element--error) .stripe-element__label {
  --tw-text-opacity: 1;
  color: rgba(39, 84, 145, var(--tw-text-opacity));
}
.stripe-element--complete, .stripe-element--focus, .stripe-element--error {
  z-index: 20;
}
.stripe-element--complete .stripe-element__label, .stripe-element--focus .stripe-element__label, .stripe-element--error .stripe-element__label {
  font-size: 11px;
  transform: translateY(-11px);
}
.stripe-element--complete .StripeElement, .stripe-element--focus .StripeElement, .stripe-element--error .StripeElement {
  transform: translateY(6px);
}
.stripe-element--disabled {
  cursor: not-allowed;
  background-color: #F0F0F0;
}
.stripe-element--disabled .stripe-element__label {
  background: #F0F0F0;
}
.stripe-element--has-icon .stripe-element__label {
  margin-left: 0.5rem;
  left: 1.6rem;
  width: calc(100% - 1.6rem - 0.5rem);
}
.stripe-element--error {
  --tw-border-opacity: 1;
  border-color: rgba(225, 38, 28, var(--tw-border-opacity));
}
.stripe-element--error .stripe-element__label {
  --tw-text-opacity: 1;
  color: rgba(225, 38, 28, var(--tw-text-opacity));
}
</style>
